import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Plausible from "plausible-tracker";

// window.location = { "ancestorOrigins": {}, "href": "https://jovemdev.com.br/", "origin": "https://jovemdev.com.br", "protocol": "https:", "host": "jovemdev.com.br", "hostname": "jovemdev.com.br", "port": "", "pathname": "/", "search": "", "hash": "" };
// window.document = {};

const plausible = Plausible({
    domain: "jovemdev.com.br",
});

plausible.enableAutoPageviews();
plausible.enableAutoOutboundTracking();
plausible.trackEvent('hello', { callback: () => console.log('sent event') });

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <App plausible={plausible} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
