import React from 'react';
import Tilt from 'react-parallax-tilt';
import { motion } from 'framer-motion';
import { styles } from '../styles';
import { services } from '../constants';
import { fadeIn, textVariant } from '../utils/motion';
import { SectionWrapper } from '../hoc';
import cv from '../assets/Curriculo-Henrique.pdf';


const ServiceCard = ({ index, title, icon }) => (
    <Tilt className='xs:w-[250px] w-full'>
        <motion.div variants={fadeIn("right", "spring", index * 0.5, 0.75)}
            className='w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card items-center'
        >
            <div
                options={{
                    max: 45,
                    scale: 1,
                    speed: 450,
                }}
                className='bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col'
            >
                <img
                    src={icon}
                    alt={title}
                    className='w-16 h-16 object-contain'
                />

                <h3 className='text-white text-[20px] font-bold text-center'>
                    {title}
                </h3>
            </div>
        </motion.div>
    </Tilt>
);

const About = () => {
    return (
        <>
            <motion.div variants={textVariant()}>
                <p className={styles.sectionSubText}>Introdução</p>
                <h2 className={styles.sectionHeadText}>Sobre</h2>
            </motion.div>
            <motion.p variants={fadeIn('', '', 0.1, 1)}
                className='mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]'>
                Sou um desenvolvedor de software qualificado, com experiência em JavaScript,
                C# .NET, SQL, RPA e expertise em frameworks como React Native e Angular.
                Possuo conhecimento em todas as fases do desenvolvimento de software, desde a análise de requisitos até a implementação e testes de soluções escaláveis, eficientes e fáceis de usar.
                Estou pronto para colaborar com você para transformar suas ideias em realidade.
            </motion.p>
            <motion.a href={cv} target="_blank" rel="noreferrer" download className="bg-[#915eff] mt-3 py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md flex justify-between items-center">
                <span className="material-symbols-outlined px-1">description</span>Currículo
            </motion.a>
            <div className='mt-20 flex flex-wrap gap-10'>
                {services.map((service, index) => (
                    <ServiceCard key={service.title} index={index} {...service} />
                ))}
            </div>
        </>
    )
}

export default SectionWrapper(About, 'about');